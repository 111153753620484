<template>
  <div id="app">
    <!--顶部导航-->
    <music-header />
    <!--视图窗口-->
    <router-view class="main-view" />
    <!--音频播放器-->
    <audio ref="audioPlayer"></audio>
    <!--视频播放器-->
    <div class="music-video" v-show="mvShow == true">
      <video controls="controls" :src="mvUrl" ref="videoPlayer"></video>
      <div class="music-mask" @click="hideMV()"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import { getPlaylistDetail } from 'api'
import { version, defaultPlayList, defaultPageSize } from '@/config'
import { createTopList } from '@/utils/song'
import MusicHeader from 'components/music-header/music-header'

export default {
  name: 'App',
  components: {
    MusicHeader
  },
  created() {
    // 获取播放列表
    getPlaylistDetail(defaultPlayList).then(playlist => {
      const list = playlist.tracks.slice(0, 100)
      this.setPlaylist({ list })
    })

    // 设置标签标题
    let OriginTitile = document.title
    let titleTime
    document.addEventListener('visibilitychange', function() {
      if (document.hidden) {
        document.title = 'o(´^｀)o咦，不见了！'
        clearTimeout(titleTime)
      } else {
        document.title = '(*^▽^*)咦，回来了!'
        titleTime = setTimeout(function() {
          document.title = OriginTitile
        }, 2000)
      }
    })

    // 设置音频播放器
    this.$nextTick(() => {
      this.setAudioele(this.$refs.audioPlayer)
    })

    // 首次加载显示更新弹窗
    let loadDOM = document.querySelector('#appLoading')
    if (loadDOM) {
      const animationendFunc = function() {
        loadDOM.removeEventListener('animationend', animationendFunc)
        loadDOM.removeEventListener('webkitAnimationEnd', animationendFunc)
        document.body.removeChild(loadDOM)
        loadDOM = null
      }.bind(this)
      loadDOM.addEventListener('animationend', animationendFunc)
      loadDOM.addEventListener('webkitAnimationEnd', animationendFunc)
      loadDOM.classList.add('removeAnimate')
    }
  },
  computed:{
    ...mapState({
      mvUrl: (state) => state.mvUrl,
      mvShow: (state) => state.mvShow
    })
  },
  methods: {
    // 歌曲数据处理
    _formatSongs(list) {
      let ret = []
      list.forEach(item => {
        const musicData = item
        if (musicData.id) {
          ret.push(createTopList(musicData))
        }
      })
      return ret
    },
    ...mapMutations({
      setAudioele: 'SET_AUDIOELE',
      setVideoState: 'SET_VIDEOSTATE'
    }),
    ...mapActions(['setPlaylist']),
    hideMV(){
      let payload = {
        "url": '',
        "flag": false
      }
      
      this.setVideoState(payload)
      this.$refs.videoPlayer.pause()
    }
  }
}
</script>

<style lang="less">
#app {
  position: relative;
  width: 100%;
  height: 100%;
  color: @text_color;
  font-size: @font_size_medium;

  .main-view {
    width: 100%;
    height: 100%;
  }

  audio {
    position: fixed;
  }

  .music-video video {
    position: fixed;
    width: 1000px;
    height: 546px;
    left: 50%;
    top: 50%;
    margin-top: -273px;
    transform: translateX(-50%);
    z-index: 990;
  }

  .music-video .music-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 980;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
</style>
