import Vue from 'vue'
import store from './store'
import router from './router'
import App from './App'

// 全局CSS样式
import '@/styles/index.less'

// 路由钩子函数
import '@/router/hook.js'

// 解决移动端点击300ms延迟
import fastclick from 'fastclick'
fastclick.attach(document.body)

// 自定义弹出层
import Toast from 'components/music-common/toast'
Vue.use(Toast)

// 自定义图标库
import Icon from 'components/music-common/icon/music-icon'
Vue.component(Icon.name, Icon)

// 播放专辑懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1,
  loading: require('assets/img/default.png')
})

// 版本统计
import { version } from './config'
window._hmt && window._hmt.push(['_setCustomVar', 1, 'version', version, 1])

// 版权信息
let info = `欢迎访问 LGMusic-倾听好音乐 (๓╰╯๓) !
版本：v${version}
作者：ProChick
邮箱：prochick@163.com
声明：所有歌曲均来源于网易云音乐`
console.log(`%c${info}`, `color:purple;font-size:13px;font-weight:bold;'`)

new Vue({
  el: '#LGMusic',
  store,
  router,
  render: h => h(App)
})
