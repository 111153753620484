import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/music'
  },
  {
    path: '/music',
    component: () => import('pages/music'),
    redirect: '/music/play',
    children: [{
        path: '/music/play',
        component: () => import('pages/play/play'),
        meta: {
          title: '播放列表',
          keepAlive: true
        }
      },
      {
        path: '/music/recommend',
        component: () => import('pages/recommend/recommend'),
        meta: {
          title: '热门推荐',
          keepAlive: true
        }
      },
      {
        path: '/music/history',
        component: () => import('pages/history/history'),
        meta: {
          title: '播放历史'
        }
      },
      {
        path: '/music/search',
        component: () => import('pages/search/search'),
        meta: {
          title: '歌曲搜索',
          keepAlive: true
        }
      },
      {
        path: '/music/like',
        component: () => import('pages/like/like'),
        meta: {
          title: '我的收藏',
          keepAlive: true
        }
      },
      {
        path: '/music/comment/:id',
        component: () => import('pages/comment/comment'),
        meta: {
          title: '歌曲评论'
        }
      },
      {
        path: '/music/details/:id',
        component: () => import('pages/details/details')
      }
    ]
  },
  {
    path: '/404',
    component: () => import('pages/error/404'),
    meta: {
      title: '页面找不到了'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export default new Router({
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})
