import {
  setMode,
  setVolume,
  setHistoryList,
  clearHistoryList,
  removeHistoryList,
  setUserLikeList,
  clearUserLikeList,
  removeUserLikeList
} from '@/utils/storage'
import * as types from './mutation-types'

function findIndex(list, music) {
  return list.findIndex(item => {
    return item.id === music.id
  })
}

// 设置播放列表
export const setPlaylist = function({ commit }, { list }) {
  commit(types.SET_PLAYLIST, list)
  commit(types.SET_ORDERLIST, list)
}
// 清空播放列表
export const clearPlayList = function({ commit }) {
  commit(types.SET_PLAYING, false)
  commit(types.SET_CURRENTINDEX, -1)
  commit(types.SET_PLAYLIST, [])
  commit(types.SET_ORDERLIST, [])
}
// 选择音乐播放（重置模式）
export const selectPlay = function({ commit }, { list, index }) {
  commit(types.SET_PLAYLIST, list)
  commit(types.SET_ORDERLIST, list)
  commit(types.SET_CURRENTINDEX, index)
  commit(types.SET_PLAYING, true)
}
// 选择音乐播放（新增模式）
export const selectAddPlay = function({ commit, state }, music) {
  let list = [...state.playlist]
  // 查询当前播放列表是否有代插入的音乐，并返回其索引值
  let index = findIndex(list, music)
  // 当前播放列表有待插入的音乐时，直接改变当前播放音乐的索引值
  if (index > -1) {
    commit(types.SET_CURRENTINDEX, index)
  } else {
    list.unshift(music)
    commit(types.SET_PLAYLIST, list)
    commit(types.SET_ORDERLIST, list)
    commit(types.SET_CURRENTINDEX, 0)
  }
  commit(types.SET_PLAYING, true)
}
// 删除正在播放列表中的歌曲
export const removerPlayListItem = function(
  { commit, state },
  { list, index }
) {
  let currentIndex = state.currentIndex
  if (index < state.currentIndex || list.length === state.currentIndex) {
    currentIndex--
    commit(types.SET_CURRENTINDEX, currentIndex)
  }
  commit(types.SET_PLAYLIST, list)
  commit(types.SET_ORDERLIST, list)
  if (!list.length) {
    commit(types.SET_PLAYING, false)
  } else {
    commit(types.SET_PLAYING, true)
  }
}

// 设置播放模式
export const setPlayMode = function({ commit }, mode) {
  commit(types.SET_PLAYMODE, setMode(mode))
}
// 设置播放音量
export const setPlayVolume = function({ commit }, volume) {
  commit(types.SET_PLAYVOLUME, setVolume(volume))
}

// 设置播放历史
export const setHistory = function({ commit }, music) {
  commit(types.SET_HISTORYLIST, setHistoryList(music))
}
// 删除播放历史
export const removeHistory = function({ commit }, music) {
  commit(types.SET_HISTORYLIST, removeHistoryList(music))
}
// 清空播放历史
export const clearHistory = function({ commit }) {
  commit(types.SET_HISTORYLIST, clearHistoryList())
}

// 设置用户收藏
export const setUserLike = function({ commit }, music) {
  commit(types.SET_USERLIKELIST, setUserLikeList(music))
}
// 删除用户收藏
export const removeUserLike = function({ commit }, music) {
  commit(types.SET_USERLIKELIST, removeUserLikeList(music))
}
// 清空用户收藏
export const clearUserLike = function({ commit }) {
  commit(types.SET_USERLIKELIST, clearUserLikeList())
}

