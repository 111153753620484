import { defaultVolume } from '@/config'

const _storage = window.localStorage
const storage = {
  get(key, data = []) {
    if (_storage) {
      return _storage.getItem(key)
        ? Array.isArray(data)
          ? JSON.parse(_storage.getItem(key))
          : _storage.getItem(key)
        : data
    }
  },
  set(key, val) {
    if (_storage) {
      _storage.setItem(key, val)
    }
  },
  clear(key) {
    if (_storage) {
      _storage.removeItem(key)
    }
  }
}

/**
 * 存储KEY
 */
const MODE_KEY = '__LGMusic_Mode__'
const VOLUME_KEY = '__LGMusic_Volume__'
const HISTORY_KEY = '__LGMusic_History__'
const USERLIKE_KEY = '__LGMusic_UserLike__'


// 获取播放模式
export function getMode() {
  return storage.get(MODE_KEY, null)
}
// 保存播放模式
export function setMode(mode) {
  storage.set(MODE_KEY, mode)
  return mode
}

// 获取播放音量
export function getVolume() {
  const volume = storage.get(VOLUME_KEY, defaultVolume)
  return Number(volume)
}
// 保存播放音量
export function setVolume(volume) {
  storage.set(VOLUME_KEY, volume)
  return volume
}

// 获取播放历史列表
export function getHistoryList() {
  return storage.get(HISTORY_KEY)
}
// 保存播放历史列表
export function setHistoryList(music) {
  let list = storage.get(HISTORY_KEY)
  const index = list.findIndex(item => {
    return item.id === music.id
  })
  if (index === 0) {
    return list
  }
  if (index > 0) {
    list.splice(index, 1)
  }
  list.unshift(music)
  
  // 控制最多记录100首歌曲
  if (list.length > 100) {
    list.pop()
  }
  storage.set(HISTORY_KEY, JSON.stringify(list))
  return list
}
// 删除播放历史列表
export function removeHistoryList(music) {
  storage.set(HISTORY_KEY, JSON.stringify(music))
  return music
}
// 清空播放历史列表
export function clearHistoryList() {
  storage.clear(HISTORY_KEY)
  return []
}

// 获取用户收藏列表
export function getUserLikeList() {
  return storage.get(USERLIKE_KEY)
}
// 保存用户收藏列表
export function setUserLikeList(music) {
  let list = storage.get(USERLIKE_KEY)
  list.unshift(music)
  storage.set(USERLIKE_KEY, JSON.stringify(list))
  return list
}
// 删除用户收藏列表
export function removeUserLikeList(music) {
  storage.set(USERLIKE_KEY, JSON.stringify(music))
  return music
}
// 清空用户收藏列表
export function clearUserLikeList() {
  storage.clear(USERLIKE_KEY)
  return []
}
