import TempToast from './toast.vue'

let instance
let showToast = false
let time
const Toast = {
  install(Vue, options = {}) {
    let opt = TempToast.data() // 获取组件中的默认配置
    Object.assign(opt, options) // 合并配置
    Vue.prototype.$Toast = (message, position) => {
      if (showToast) {
        clearTimeout(time)
        instance.vm.visible = showToast = false
        document.body.removeChild(instance.vm.$el)
      }
      if (message) {
        opt.message = message
      }
      if (position) {
        opt.position = position
      }
      let TempToastConstructor = Vue.extend(TempToast)
      instance = new TempToastConstructor({
        data: opt
      })
      instance.vm = instance.$mount()
      document.body.appendChild(instance.vm.$el)
      instance.vm.visible = showToast = true

      time = setTimeout(function() {
        instance.vm.visible = showToast = false
        document.body.removeChild(instance.vm.$el)
      }, opt.duration)
    }
  }
}

export default Toast
