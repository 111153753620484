import axios from 'axios'
import Vue from 'vue'

export function request(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL
    // timeout: 3000,
    // headers: {
    //   'content-type': 'application/json;charset=UTF-8'
    // }
  })

  instance.interceptors.response.use(res => {
    window.response = res
    if (res.status === 200 && res.data.code === 200) {
      return res.data
    }
    return Promise.reject(res)
  }, err => {
    Vue.prototype.$Toast(
      err.response ? err.response.data.message : err.message
    )
    return err
  })

  return instance(config)
}
