// 当前版本
export const version = process.env.VUE_APP_VERSION

// 默认播放列表（https://music.163.com/#/discover/toplist?id=19723756）
export const defaultPlayList = 19723756

// 默认分页数量
export const defaultPageSize = 30

// 默认页面背景
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./assets/background', false)
const BG_ARR = requireAll(req)
export const defaultBG = BG_ARR[Math.floor(Math.random() * BG_ARR.length)]

// 默认播放音量
export const defaultVolume = 0.6

// 定义播放模式
export const playMode = {
  listLoop: 0,
  order: 1,
  random: 2,
  loop: 3
}
