import { playMode, defaultVolume } from '@/config'
import { getMode, getVolume, getHistoryList, getUserLikeList } from '@/utils/storage'

const state = {
  audioEle: null, // 音频元素
  mode: Number(getMode()) || playMode.listLoop, // 播放模式
  volume: Number(getVolume()) || defaultVolume, // 播放音量
  playing: false, // 播放状态
  playlist: [], // 播放列表
  orderList: [], // 顺序列表
  currentIndex: -1, // 当前音乐索引
  mvUrl:'', // 当前音乐MV地址
  mvShow:false, // 当前音乐MV是否展示
  historyList: getHistoryList() || [], // 播放历史列表
  userLikeList: getUserLikeList() || [] // 用户收藏列表
}

export default state
