import {
  request
} from '@/api/request'
import {
  defaultPageSize
} from '@/config'
import {
  formatTopSongs
} from '@/utils/song'

// 推荐榜单
export function getRecommendRank() {
  return request({
    url: '/toplist/detail',
    method: 'get'
  })
}
// 推荐歌曲
export function getRecommendSong() {
  return request({
    url: '/personalized/newsong',
    method: 'get',
    params: {
      limit: 30
    }
  })
}
// 推荐歌单
export function getRecommendSheet() {
  return request({
    url: '/personalized',
    method: 'get',
    params: {
      limit: 20
    }
  })
}
// 推荐MV
export function getRecommendMV() {
  return request({
    url: '/mv/all',
    method: 'get',
    params: {
      limit: 20
    }
  })
}

// 歌曲检查
export function getCheckMusic(id) {
  return request({
    url: '/check/music',
    method: 'get',
    params: {
      id
    }
  })
}
// 歌曲详情
export function getMusicDetail(ids) {
  return request({
    url: '/song/detail',
    method: 'get',
    params: {
      ids
    }
  })
}
// 歌单详情
export function getPlaylistDetail(id) {
  return new Promise((resolve, reject) => {
    request({
      url: '/playlist/detail',
      method: 'get',
      params: {
        id
      }
    })
      .then(({
        playlist
      }) => playlist || {})
      .then(playlist => {
        const {
          trackIds,
          tracks
        } = playlist

        if (!Array.isArray(trackIds)) {
          reject(new Error('歌单详情获取失败'))
          return
        }

        // 排行歌曲
        if (tracks.length === trackIds.length) {
          playlist.tracks = formatTopSongs(playlist.tracks)
          resolve(playlist)
          return
        }

        // 歌单歌曲
        const ids = trackIds
          .slice(0, 500)
          .map(v => v.id)
          .toString()
        getMusicDetail(ids).then(({
          songs
        }) => {
          playlist.tracks = formatTopSongs(songs)
          resolve(playlist)
        })
      })
  })
}
// 歌曲搜索
export function search(keywords, page = 0, limit = defaultPageSize) {
  return request({
    url: '/search',
    method: 'get',
    params: {
      offset: page * limit,
      limit: limit,
      keywords
    }
  })
}
// 搜索热词
export function searchHot() {
  return request({
    url: '/search/hot',
    method: 'get'
  })
}

// 音乐地址
export function getMusicUrl(id) {
  return request({
    url: '/song/url',
    method: 'get',
    params: {
      id
    }
  })
}
// 视频地址
export function getMovieUrl(id) {
  return request({
    url: 'mv/url',
    method: 'get',
    params: {
      id
    }
  })
}

// 音乐歌词
export function getLyric(id) {
  return request({
    url: '/lyric',
    method: 'get',
    params: {
      id
    }
  })
}
// 音乐评论
export function getComment(id, page, limit = defaultPageSize) {
  return request({
    url: '/comment/music',
    method: 'get',
    params: {
      offset: page * limit,
      limit: limit,
      id
    }
  })
}
