import router from '../router'

const redirectList = ['/music/details', '/music/comment']

// 路由跳转之前
router.beforeEach((to, from, next) => {
  window._hmt &&
    to.path &&
    window._hmt.push(['_trackPageview', '/#' + to.fullPath])
  if (redirectList.includes(to.path)) {
    next()
  } else {
    document.title = (to.meta.title && `${to.meta.title} - LGMusic倾听好音乐`) || 'LGMusic倾听好音乐'
    next()
  }
})

// 路由跳转之后
router.afterEach(() => {

})
