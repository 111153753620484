import * as types from './mutation-types'

const mutations = {
  // 修改音频元素
  [types.SET_AUDIOELE](state, audioEle) {
    state.audioEle = audioEle
  },
  // 修改播放模式
  [types.SET_PLAYMODE](state, mode) {
    state.mode = mode
  },
  // 修改播放音量
  [types.SET_PLAYVOLUME](state, volume) {
    state.volume = volume
  },
  // 修改播放状态
  [types.SET_PLAYING](state, playing) {
    state.playing = playing
  },
  // 修改播放列表
  [types.SET_PLAYLIST](state, playlist) {
    state.playlist = playlist
  },
  // 修改顺序列表
  [types.SET_ORDERLIST](state, orderList) {
    state.orderList = orderList
  },
  // 修改视频播放状态
  [types.SET_VIDEOSTATE](state, payload){
    state.mvUrl = payload.url
    state.mvShow = payload.flag
  },
  // 修改当前音乐索引
  [types.SET_CURRENTINDEX](state, currentIndex) {
    state.currentIndex = currentIndex
  },
  // 修改播放历史列表
  [types.SET_HISTORYLIST](state, historyList) {
    state.historyList = historyList
  },
  // 修改用户收藏列表
  [types.SET_USERLIKELIST](state, userLikeList) {
    state.userLikeList = userLikeList
  }
}

export default mutations
