<template>
  <header class="music-header">
    <h1 class="header">
      <a href="/">
        ♫ LGOnlineMusic ♫
      </a>
    </h1>
  </header>
</template>

<script>
export default {
  name: 'MusicHeader',
  data() {
    return{

    }
  },
  methods: {}
}
</script>

<style lang="less">
.music-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  @media (max-width: 768px) {
    background: @header_bg_color;
  }
  .header {
    text-align: left;
    line-height: 60px;
    padding-left: 15px;
    color: @text_color_active;
    font-size: @font_size_large_x;
    @media (max-width: 768px) {
      // padding-left: 15px;
      // text-align: left;
      text-align: center;
    }
    font-family: "Montserrat";
    text-shadow: 4px 4px 2px #888;
    text-shadow: 1px 1px 0 #ff3f1a, -1px -1px 0 #00a7e0;
    transition: all 0.8s;

    img{
      width: 140px;
      height: 40px;
      margin-top: 10px;
    }
  }
  .header:hover{
    text-shadow: 4px 4px 2px #888;
    text-shadow: 1px 1px 0 #00a7e0, -1px -1px 0 #5B86E5;
  }
}
</style>
